










import {Vue, Component, Watch, Emit, Ref} from "vue-property-decorator";
import AddressBookSearchType from "./indexType";
import Search from "@/components/ShareComponent/Search.vue";
import {FeedbackOrderStore} from "@/views/Express/FeedbackOrder/Store";


@Component({name: "AddressBookSearch",components:{ Search }})
export default class AddressBookSearch extends Vue implements AddressBookSearchType{
    @Ref("searchFriendInput")
    InputObj:any

    show = false
    value = ""

    handleChangeShow(){
        this.show = true
        let time = setTimeout(()=>{
            this.InputObj.focus()
            clearTimeout(time)
        })
    }

    handleBlur(){
        if ( this.value.length < 1 ) this.show = false;
    }

    handleClickDown(){
        if ( !(this.show && this.value.length) )return;
        let upData = FeedbackOrderStore.getFeedbackOrderUpData;
        upData.mainOrderId = this.value
        FeedbackOrderStore.SetFeedbackOrderUpData(upData)
        this.pullSearch()
    }

    @Watch("value")
    changeValue(newVal:string){
        if ( newVal.length <= 0 ){
            let upData = FeedbackOrderStore.getFeedbackOrderUpData;
            try{
                delete upData.mainOrderId
            }catch (e) { }
            upData.pageNo = 1
            FeedbackOrderStore.SetFeedbackOrderUpData(upData)
        }
    }

    get getClear(){ return FeedbackOrderStore.getFeedbackOrderClear }
    @Watch("getClear")
    changeClear(newVal:boolean){
        if ( newVal ){
            this.value = ""
            this.handleBlur()
        }
    }

    @Emit("pullSearch")
    pullSearch(){
        return
    }
}
