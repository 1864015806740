


















































import {Vue, Component, Emit} from "vue-property-decorator";
import FeedbackSelectOrderType from "./indexType";
import PullItem from "@/components/ShareComponent/PullSelItem.vue"
import {GetFromType, RoomType} from "@/Type/index";
import {FeedbackOrderStore} from "@/views/Express/FeedbackOrder/Store/index";
import {OrderStatus, ZoomList} from '../../Model';
import {StoreExpress} from "@/store/modules/Express/Express";
import {FilterPullItem} from "@/util/filter";
import {UserStore} from "@/store/modules/User";
import {getFromList} from '../../Server';
import { getToList } from "@/server";

@Component({name: "FeedbackSelectOrder", components: {PullItem,}})
export default class FeedbackSelectOrder extends Vue implements FeedbackSelectOrderType {
    SelShowList = [false, false, false, false,]
    ZoomList: any[] = [];
    ZoomIndex = 0
    StatusList: any[] = [];
    StatusIndex = 0
    FromList: Array<GetFromType> = [];
    FromIndex = 0;
    ToList: Array<RoomType> = []
    ToIndex = 0;

    mounted(){
        this.ZoomList = ZoomList
        this.StatusList = OrderStatus
        this.initData()
    }

    initData(){
        if ( StoreExpress.GetFromList.length ) {
            this.FromList = FilterPullItem(StoreExpress.GetFromList,"name") as Array<GetFromType>
        }else{
            getFromList().then(res=>{
                this.FromList = res as []
            })
        }
        if( UserStore.GetRoomList.length ){
            this.ToList = FilterPullItem(UserStore.GetRoomList,"dicCode")
        }else{
            getToList().then(res=>{
                this.ToList = res
            })
        }
    }

    handlePullItem(data: any) {
        let upData = FeedbackOrderStore.getFeedbackOrderUpData;
        upData.pageNo = 1
        switch (Number(data.state)) {
            case 0:
                upData.order = data.data.state
                this.ZoomIndex = data.index
                break;
            case 1:
                upData.status = data.data.state
                this.StatusIndex = data.index
                break;
            case 2:
                this.FromIndex = data.index
                upData.mailCompany = data.data.title
                break;
            case 3:
                this.ToIndex = data.index
                upData.buildNumberName = data.data.title
                break;
        }
        FeedbackOrderStore.SetFeedbackOrderUpData(upData)
        this.pullSearch()
    }

    handleClickPull(idx: number) {
        let List: boolean[] = []
        if ( idx == 4 ){
            let upData = FeedbackOrderStore.getFeedbackOrderUpData;
            FeedbackOrderStore.SetFeedbackOrderUpData({pageNo:1,pageSize:15,userId:upData.userId})
            FeedbackOrderStore.SetFeedbackOrderClear(true)
            this.pullSearch()
        }
        this.SelShowList.forEach((item,index)=>{
            if ( idx == 4 ){
                List.push( false )
                return;
            }
            if ( idx == index ) {
                List.push( !item )
            }else{
                List.push( false )
            }
        })
        this.SelShowList = List
    }

    @Emit("pullSearch")
    pullSearch(){
        return
    }
}
