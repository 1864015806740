
























import {Vue, Component, Watch} from "vue-property-decorator";
import FeedbackOrderType from "./FeedbackOrder";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import AddressBookSearch from "@/views/Express/FeedbackOrder/components/Search/index.vue";
import FeedbackSelectOrder from "@/views/Express/FeedbackOrder/components/Select/index.vue";
import {FeedbackOrderStore} from "@/views/Express/FeedbackOrder/Store/index";
import MyCom from '@/components/ExpressComponent/MyExpressComponent.vue'
import {getFindExpress} from "@/views/Express/FeedbackOrder/Server";

@Component({name: "FeedbackOrder",components:{ HeadTop,PullDownUpList,ListBottom,AddressBookSearch,FeedbackSelectOrder,MyCom }})
export default class FeedbackOrder extends ZoomPage implements FeedbackOrderType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []
    id = ""

    mounted(){
       this.loadingShow = false
    }

    activated(){
        let { id = "" } = this.$route.query
        if ( id != this.id ){
            this.id = id as string;
            this.UpDownBool = true
            let upData = FeedbackOrderStore.getFeedbackOrderUpData
            upData.userId = id as string
            FeedbackOrderStore.SetFeedbackOrderUpData(upData)
            this.handleUpData(true)
        }
    }

    handleSelectSearch(){
        this.UpDownBool = true
        this.handleUpData(true)
    }

    handlePullDown(){
        this.PullDown = true
        let upData = FeedbackOrderStore.getFeedbackOrderUpData
        upData.pageNo = 1;
        FeedbackOrderStore.SetFeedbackOrderUpData(upData)
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        this.UpDownBool = true
        let upData = FeedbackOrderStore.getFeedbackOrderUpData
        upData.pageNo += 1;
        FeedbackOrderStore.SetFeedbackOrderUpData(upData)
        this.handleUpData()
    }

    handleUpData(bool=false){
        getFindExpress().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FeedbackOrderStore.getFeedbackOrderUpData.pageSize )this.StopUp = true;
            if ( bool )
                FeedbackOrderStore.SetFeedbackOrderList( res );
            else
                FeedbackOrderStore.SetFeedbackOrderList( FeedbackOrderStore.getFeedbackOrderUpData.concat(res) );
        })
    }

    get getList(){
        return FeedbackOrderStore.getFeedbackOrderList
    }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }

}
