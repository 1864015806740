























































import { IssuePay,IssuePayBack } from "@/Api/Express/index"
import { Dialog,Notify } from 'vant'
import { DDTimes } from '@/util/time'
import Stroage from '@/util/Storage'
import { IndexStore } from '@/store/modules/Index'
import { StoreExpress } from '@/store/modules/Express/Express'
import { Vue,Component,Prop,Emit } from "vue-property-decorator"

// let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}
declare let WeixinJSBridge: any;
declare const document:any;

interface MyComType<T=string>{
    loadingShow:boolean;
    timeIcon:T;
    activeIcon:T;
    userId:T|number;
    token:T;
    OrderId:T;
    OrderData:any;

    handleToPath():void;
    handleFilterTime(str:T):void;
    handleFilterStr(state:T|number):T;
    handleClickBtn(e:Event):void;
    handlePay():void;
    onBridgeReady():void;
    handleCallBack():void;
}

@Component({ name:"MyExpressComponent" })
export default class MyExpressComponent extends Vue implements MyComType{
   loadingShow = false
   timeIcon = require("$icon/Express/Time.png");
   activeIcon = require("$icon/Express/Active.png");
   userId:string|number = ""
   token = ""
   OrderId = ""
   OrderData:any = {}

   @Prop(Object)
   data!:any;

   get GetData(){
       return this.data
   }

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
   }

   handleToPath(){
       let data = {
           ...StoreExpress.GetExpScroll,
           MyScroll:IndexStore.GetScrollNum
       }
       StoreExpress.SetExpressScroll(data)
       this.$router.push({
           path:"/expressSuc",
           query:{
               orderId:this.GetData.mainOrderId as string,
               id:this.GetData.id
           }
       })
   }

   handleFilterTime( str:string ){
       return DDTimes(str)
   }

   handleFilterStr(state:string|number):string{
       switch( Number(state) ){
           case 0:
               return "未支付";
           case 1:
               return "无人接单";
           case 2:
               return "已接单";
           case 3:
               return "待确认";
           case 4:
               return "已完成";
           case 5:
               return "订单异常";
           case 6:
               return "已取消";
           case 7:
               return "已删除";
           default:
               return "其他"
       }
   }

   handleClickBtn(e:Event){
       if( this.GetData.status != 0 ){
           e.preventDefault()
           e.stopPropagation()
           return;
       }
       this.OrderId = this.GetData.mainOrderId as string
       this.loadingShow = true
       IssuePay({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId
       }).then( (res:any)=>{
           if( res.message.code === "200" ){
               this.OrderData = res.data
               this.handlePay()
           }else{
               this.loadingShow = false
               this.handleError( res.message.msg )
           }
       } )
       e.preventDefault()
       e.stopPropagation()
   }

   handlePay(){
       if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
           if( document.addEventListener ){
               document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
           }else if ( "attachEvent" in document ){
               try{
                   document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                   document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
               }catch (e) {
                   console.info("事件不存在" + e)
               }
           }
       }else{
           this.onBridgeReady();
       }
   }

   onBridgeReady(){
       ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
               "appId":this.OrderData.appId,//公众号名称，由商户传入
               "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
               "nonceStr":this.OrderData.nonceStr, //随机串
               "package":this.OrderData.package,
               "signType":this.OrderData.signType,//微信签名方式：
               "paySign":this.OrderData.paySign //微信签名
           },
           (res:any)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                   this.handleCallBack()
               }
           });
   }

   handleCallBack(){
       IssuePayBack({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId
       }).then(res=>{
           this.loadingShow = false
           if (res.message.code === "200") {
               Dialog.confirm({
                   title: '支付成功',
                   message: '请刷新列表',
                   className:"HideClassName",
                   overlayClass:"MaskHideClassName"
               }).then( res=>{
                   this.Reftch()
               } ).catch( cat=>cat )
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Emit("Reftch")
   Reftch(){
       return true
   }
}
